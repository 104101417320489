import { render, staticRenderFns } from "./v-learn-common.vue?vue&type=template&id=1adde5c2&scoped=true"
import script from "./v-learn-common.vue?vue&type=script&lang=js"
export * from "./v-learn-common.vue?vue&type=script&lang=js"
import style0 from "./v-learn-common.vue?vue&type=style&index=0&id=1adde5c2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1adde5c2",
  null
  
)

export default component.exports