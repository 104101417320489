import bearer from '@websanova/vue-auth/drivers/auth/bearer'
import axios from '@websanova/vue-auth/drivers/http/axios.1.x'
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x'
// Auth base configuration some of this options
// can be override in method calls
const config = {
  auth: bearer,
  http: axios,
  router: router,
  tokenDefaultKey: 'jwtdemo',
  tokenStore: ['sessionStorage'],
  
  rolesKey: 'role',
  registerData: {url: 'User/SignUp', method: 'POST', redirect: ''},
  loginData: {url: '/Auth/Authenticate', method: 'POST', redirect: '', fetchUser: true},
  logoutData: {url: '/adminuser/Logout', method: 'Get', redirect: '/login', makeRequest: true},
  // fetchData: { url: "/User/Get", method: "GET", enabled: true }, //it is called after refreshData when we refresh our page
  fetchData: {
    url: "/User/Get",
    method: "GET",
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtdemo')}`,
      'Content-Type': 'application/json'
    },
    enabled: true,
  },
  
  refreshData: {url: 'auth/refresh', method: 'GET', enabled: false, interval: 30}
}
export default config
