<template>
  <div style="background-color: #f4f3ef;margin-left:-7px;margin-right:-7px">
    <!-- <div class="row shadow-div padding-div">
      
           -->
    <div class="row padding-div">
      <div class="col-md-6 col-xl-3 col-sm-12 dash-card-padding">
        <div class="card card-dashboard">
          <div class="card-body card-body-dashboard">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-theme-color">
                    <i class="fas fa-users"></i>
                  </div>
                </div>
                <div class="col-7 dash-card-padding">
                  <div class="numbers">
                    <!-- <p>{{ productionTodayKgs }} Count</p> -->
                    {{ dashboardData.employeeCount > 0 ? dashboardData.employeeCount:0 }}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div class="stats">
                  <i role="button" title="Reload" class="fas fa-sync-alt"></i>
                  &nbsp;|&nbsp; Registered Users
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3 col-sm-12 dash-card-padding">
        <div class="card card-dashboard">
          <div class="card-body card-body-dashboard">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-theme-color">
                    <i class="fas fa-user-graduate"></i>
                  </div>
                </div>
                <div class="col-7 dash-card-padding">
                  <div class="numbers">
                    <!-- <p>{{ productionMonthKgs }} Count</p> -->
                    {{ dashboardData.employeeCount > 0 ? dashboardData.employeeCount:0 }}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div class="stats">
                  <i role="button" title="Reload" class="fas fa-sync-alt"></i>
                  &nbsp;|&nbsp;Employees
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3 col-sm-12 dash-card-padding">
        <div class="card card-dashboard">
          <div class="card-body card-body-dashboard">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-theme-color">
                    <i class="fas fa-building"></i>
                  </div>
                </div>
                <div class="col-7 dash-card-padding">
                  <div class="numbers">
                    <!-- <p>{{ shipTodayKgs }} Count</p> -->
                    {{ dashboardData.employerCount > 0 ? dashboardData.employerCount:0 }}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div class="stats">
                  <i role="button" title="Reload" class="fas fa-sync-alt"></i
                  >&nbsp;|&nbsp; Employers
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3 col-sm-12 dash-card-padding">
        <div class="card card-dashboard">
          <div class="card-body card-body-dashboard">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-theme-color">
                    <i class="fas fa-folder-plus"></i>
                  </div>
                </div>
                <div class="col-7 dash-card-padding">
                  <div class="numbers">
                    <!-- <p>{{ this.shipMonthKgs }} Count</p> -->
                    {{ dashboardData.applicantsCount > 0 ? dashboardData.applicantsCount:0 }}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div class="stats">
                  <i role="button" title="Reload" class="fas fa-sync-alt"></i>
                  &nbsp;|&nbsp; Applications
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 70%; overflow-x: auto; margin: auto; margin-top: 5%">
        <BarGraph :chartData="graphData" :options="graphOptions"></BarGraph>
      </div>
      <div style="width: 70%; margin: auto; margin-top: 5%">
        <BarGraph :chartData="employeeGenderGraphData" :options="employeeGenderGraphOptions"></BarGraph>
      </div>
    </div>
    
    <!--SPINNER-->

    <Spinner
      class="spinner"
      v-if="isLoading"
      message="Loading..."
      size="medium"
      line-fg-color="rgb(222 224 219"
      line-bg-color="rgb(68 66 62)"
    >
    </Spinner>
    <!--/SPINNER-->

    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
import BarGraph from "../../common/pages/bar-graph.vue";
// import config from '../config.js';
import Spinner from "vue-simple-spinner";
import Toaster from "../../common/pages/toaster.vue";
// import { object } from "underscore";
export default {
  name: "Dashboard",
  components: {
    Toaster,
    Spinner,
    BarGraph,
  },
  data() {
    return {
      dashboardData: {
        employeeCount:0,
        employerCount:0,
        applicantsCount:0,
        applicationsCOunt:0
      },
      isLoading: false,
      FromDate: new Date(),
      ToDate: new Date(),
      graphOptions: Object,
      graphData: null,
      employeeGenderGraphOptions:Object,
      employeeGenderGraphData:null,
      shipMonthKgs: null,
      shipMonthSacks: null,
      shipTodayKgs: null,
      shipTodaySacks: null,
      productionMonthKgs: null,
      productionMonthSacks: null,
      productionTodayKgs: null,
      productionTodaySacks: null,
    };
  },

  created() {
    this.FromDate = new Date(
      this.FromDate.getFullYear(),
      this.FromDate.getMonth() - 1,
      1,
      this.FromDate.getHours()
    );
    this.ToDate = new Date(
      this.ToDate.getFullYear(),
      this.ToDate.getMonth() + 1,
      0,
      this.ToDate.getHours()
    );
    this.getDashboard();
    this.generateGraph();
    this.generateEmployeeGenderGraph();
  },

  methods: {
    generateGraph() {
      this.graphOptions = {
        responsive: true,
        maintainAspectRatio: false,
        title: { display: true, text: "Employees From Each city" },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontSize: 12,
                color :"black",
                gridLines: {
            display: false,
          },
                callback: function (label) {
                  return label.toLocaleString() + " ";
                },
              },
            },
          ],
          xAxes: [{
  ticks: {
    autoSkip: false, 
    maxRotation: 150,
    minRotation: 5
  },gridLines: {
            display: false,
          },
          barThickness: 20, // Fixed bar width for each bar
          maxBarThickness: 40, // Maximum bar width
}]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem) {
              return (
                " " + tooltipItem.yLabel.toLocaleString()
              );
            },
          },
        },
      };
     this.getGraphData();
    },

    getGraphData() {
      // this.isLoading = true;
      axios
        .get("/Employee/GetEmployeesAgainstCity")
        .then((res) => {
          console.log(res)
          this.isLoading = false;

          if (res.data.status === "Success") {
        const data = res.data.data;

        
        this.graphData = {
          labels:  data.map((item) => item.city),
          datasets: [
            {
              label: "Number of Employees",
              data: data.map((item) => item.employeesCount),  
              backgroundColor: "#ADD8E6", // Adjusted color for visibility
            },
          ],
        };
      } else {
        this.graphData = null;
        this.$refs.toaster.showToast("Error", "danger", res.data.msg);
      }
        });
    },
    generateEmployeeGenderGraph() {
      this.employeeGenderGraphOptions = {
        responsive: true,
        maintainAspectRatio: false,
        title: { display: true, text: "Employees Gender" },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (label) {
                  return label.toLocaleString() + " ";
                },
              },
            },
          ],
          xAxes: [{
  ticks: {
    autoSkip: false, 
    maxRotation: 150,
    minRotation: 5
  },gridLines: {
            display: false,
          },
}]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem) {
              return (
                " " + tooltipItem.yLabel.toLocaleString()
              );
            },
          },
        },
      };
     this.getEmployeeGenderGraphData();
    },

    getEmployeeGenderGraphData() {
      // this.isLoading = true;
      axios
        .get("/Employee/GetEmployeesAgainstGender")
        .then((res) => {
          console.log(res)
          this.isLoading = false;

          if (res.data.status === "Success") {
        const data = res.data.data;

        
        this.employeeGenderGraphData = {
          labels:  data.map((item) => item.gender),
          datasets: [
            {
              label: "Number of Employees",
              data: data.map((item) => item.employeeCount),  
              backgroundColor: "rgba(15, 10, 222, 1)", // Adjusted color for visibility
            },
          ],
        };
      } else {
        this.employeeGenderGraphData = null;
        this.$refs.toaster.showToast("Error", "danger", res.data.msg);
      }
        });
    },

    getDashboard() {
      this.isLoading = false;

      axios
        .get("/Employee/TotalEmployee")
        .then((res) => {
          if (res.data.status == "Success") {
            this.dashboardData.employeeCount = res.data.data;
            this.populateData();
          } else {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          }
        })
        .catch((err) => {
          console.warn("error during http call", err);
        });
        axios
        .get("/Employee/TotalEmployer")
        .then((res) => {
          if (res.data.status == "Success") {
            this.dashboardData.employerCount = res.data.data;
          } else {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          }
        })
        .catch((err) => {
          console.warn("error during http call", err);
        });
        axios
        .get("/job/TotalApplicant")
        .then((res) => {
          if (res.data.status == "Success") {
            this.dashboardData.applicantsCount = res.data.data;
          } else {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          }
        })
        .catch((err) => {
          console.warn("error during http call", err);
        });
    },
    populateData() {
      let productionData = this.dashboardData.productionData;

      this.productionMonthKgs =
        productionData.productionMonthKgs.toLocaleString();
      this.productionMonthSacks =
        productionData.productionMonthSacks.toLocaleString();
      this.productionTodayKgs =
        productionData.productionTodayKgs.toLocaleString();
      this.productionTodaySacks =
        productionData.productionTodaySacks.toLocaleString();

      let shipmentData = this.dashboardData.shipmentData;
      this.shipMonthKgs = shipmentData.shipMonthKgs.toLocaleString();
      this.shipMonthSacks = shipmentData.shipMonthSacks.toLocaleString();
      this.shipTodayKgs = shipmentData.shipTodayKgs.toLocaleString();
      this.shipTodaySacks = shipmentData.shipTodaySacks.toLocaleString();
    },
  },
};
</script>