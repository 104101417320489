import VueRouter from 'vue-router'
// Pages

import Login from '../pages/authentication/login'
import Users from '../pages/user-management/users.vue'
import Dashboard from '../pages/dashboard//dashboard.vue';
import PageNotFound from '../common/pages/page-not-found';
import JobsRequests from '../pages/jobs-management/jobs-requests.vue';
import PostedJobs from '../pages/jobs-management/posted-jobs.vue'
import Applicants from '../pages/jobs-management/applicants.vue'
import Candidates from '../pages/candidates-management/candidates.vue'
import Packages from '../pages/package-management/packages.vue';
import AddsManagement from '../pages/adds-management/adds.vue';
import BrandsManagement from '../pages/brands-management/brands.vue';
import EmailTemplateManagement from '../pages/email-template-management/email-templates.vue';
import Dropdowns from '../pages/dropdowns/dropdowns.vue';
import Companies from '../pages/company-management/companies.vue';
import VouchersAndDiscounts from '../pages/discount-voucher-management/discount-vouchers.vue';

import Webinars from '../pages/webinars-management/webinars.vue';
import Stories from '../pages/stories-management/stories.vue';
import NewsOfDay from '../pages/stories-management/NewsOfDay.vue';
import UpcomingEvents from '../pages/stories-management1/UpcomingEvents.vue';
import Events from '../pages/events-management/events.vue';
import Franchise from '../pages/franchise-management/franchise.vue';
import Testimonials from '../pages/testimonials-management/testimonials.vue';
import DirectoryCompanies from '../pages/directory-companies-management/directory-companies.vue';
import FAQ from '../pages/faq-management/faq.vue';
import feedbacks from '../pages/feedbacks/feedbacks.vue';
import Courses from '../pages/v-learn-management/courses.vue';
import feedbacksmw from '../pages/feedbacks/feedbacksmw.vue';
import Ventures from "../pages/venture-management/ventures.vue"
import VenturePackages from "../pages/venture-management/ventures-packages.vue"
import VentureServices from "../pages/venture-management/ventures-services.vue"
import VenturePartners from "../pages/venture-management/ventures-partners.vue"
import WhyVentureHer from "../pages/venture-management/ventures-whyVentureHer.vue"

import VlearnCommonFile from "../pages/v-learn-management/v-learn-common.vue"
import VideosandWebinars from "../pages/VideosAndWebinars/videos-and-webinars.vue"



// router.beforeEach((to, from, next) => {
//     const isAuthenticated = sessionStorage.getItem('token') !== null; // Check if token exists

//     // If the route requires authentication and the user is not authenticated, redirect to login
//     if (to.meta.auth && !isAuthenticated) {
//         next({ name: 'login' }); // Redirect to login page
//     } else {
//         next(); // Proceed to the requested route
//     }
// });

// Routes
const routes = [{
        path: '/',
        name: 'home',
        redirect: { name: 'dashboard' },
        meta: {
            auth: true
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            auth: true
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
            auth: true
        }
    },
    {
        path: '/jobs-requests',
        name: 'jobs-requests',
        component: JobsRequests,
        meta: {
            auth: true
        }
    },
    {
        path: '/jobs-posted',
        name: 'jobs-posted',
        component: PostedJobs,
        meta: {
            auth: true
        }
    },
    {
        path: '/job-applicants',
        name: 'job-applicants',
        component: Applicants,
        meta: {
            auth: true
        }
    },
    {
        path: '/candidates',
        name: 'candidates',
        component: Candidates,
        meta: {
            auth: true
        }
    },
    {
        path: '/packages',
        name: 'packages',
        component: Packages,
        meta: {
            auth: true
        }
    },
    {
        path: '/adds-management',
        name: 'adds-management',
        component: AddsManagement,
        meta: {
            auth: true
        }
    },
    {
        path: '/brands-management',
        name: 'brands-management',
        component: BrandsManagement,
        meta: {
            auth: true
        }
    },
    {
        path: '/email-template-management',
        name: 'email-template-management',
        component: EmailTemplateManagement,
        meta: {
            auth: true
        }
    },
    {
        path: '/companies',
        name: 'companies',
        component: Companies,
        meta: {
            auth: true
        }
    },
    {
        path: '/vouchers',
        name: 'vouchers',
        component: VouchersAndDiscounts,
        meta: {
            auth: true
        }
    },
    {
        path: '/webinars',
        name: 'webinars',
        component: Webinars,
        meta: {
            auth: true
        }
    },
    {
        path: '/stories',
        name: 'stories',
        component: Stories,
        meta: {
            auth: true
        }
    },
    {
        path: '/stories/newsofday',
        name: 'NewsOfDay',
        component: NewsOfDay,
        meta: {
            auth: true
        }
    },
    {
        path: '/UpcomingEvents',
        name: 'UpcomingEvents',
        component: UpcomingEvents,
        meta: {
            auth: true
        }
    },
    {
        path: '/events',
        name: 'events',
        component: Events,
        meta: {
            auth: true
        }
    },
    {
        path: '/franchise',
        name: 'franchise',
        component: Franchise,
        meta: {
            auth: true
        }
    },
    {
        path: '/testimonials',
        name: 'testimonials',
        component: Testimonials,
        meta: {
            auth: true
        }
    },
    {
        path: '/directory-companies',
        name: 'directorycompanies',
        component: DirectoryCompanies,
        meta: {
            auth: true
        }
    },
    {
        path: '/faq',
        name: 'directofaqry',
        component: FAQ,
        meta: {
            auth: true
        }
    },
    {
        path: '/feedbacks',
        name: 'feedbacks',
        component: feedbacks,
        meta: {
            auth: true
        }
    },
    {
        path: '/feedbacksmw',
        name: 'feedbacksmw',
        component: feedbacksmw,
        meta: {
            auth: true
        }
    },
    {
        path: '/courses',
        name: 'courses',
        component: Courses,
        meta: {
            auth: true
        }
    },
    {
        path: '/viilearn/:type',
        name: 'VlearnCommonFile',
        component: VlearnCommonFile,
        meta: {
            auth: true
        }
    },
    {
        path: '/videos-and-webinars',
        name: 'VideosandWebinars',
        component: VideosandWebinars,
        meta: {
            auth: true
        }
    },
    {
        path: '/ventures',
        name: 'ventures',
        component: Ventures,
        meta: {
            auth: true
        }
    },
    {
        path: '/venture-packages',
        name: 'venturesPackages',
        component: VenturePackages,
        meta: {
            auth: true
        }
    },
    {
        path: '/venture-services',
        name: 'venturesServices',
        component: VentureServices,
        meta: {
            auth: true
        }
    },
    {
        path: '/venture-partners',
        name: 'venturesPartners',
        component: VenturePartners,
        meta: {
            auth: true
        }
    },
    {
        path: '/why-venture-her',
        name: 'whyVentureHer',
        component: WhyVentureHer,
        meta: {
            auth: true
        }
    },
    {
        path: '/dropdowns/:id',
        name: 'dropdowns',
        component: Dropdowns,
        meta: {
            auth: true
        }
    },

    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: false
        }
    },


    { path: '/404', name: 'PageNotFound', component: PageNotFound },
    { path: "*", name: 'redirectPageNotFound', redirect: '/404' }
]
const router = new VueRouter({
    mode: 'history', // Change this to 'history'
    routes: routes
  });
// const router = new VueRouter({
//     history: true,
//     mode: 'hash',
//     routes: routes
// })
export default router
