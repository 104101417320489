import { render, staticRenderFns } from "./packages.vue?vue&type=template&id=6be5df6d&scoped=true"
import script from "./packages.vue?vue&type=script&lang=js"
export * from "./packages.vue?vue&type=script&lang=js"
import style0 from "./packages.vue?vue&type=style&index=0&id=6be5df6d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6be5df6d",
  null
  
)

export default component.exports